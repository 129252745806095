<template>
    <div v-if="(filteredWebinars.length > 0 || isOwner)" class="bg-white rounded-2xl p-4 relative">
		<div class="pb-6 pt-3 flex flex-row justify-between">
			<h3 class="text-bold flex items-center gap-1">Upcoming webinars</h3>
			<div v-if="isOwner" class="relative">
				<AppButton link icon="plus" size="md" color="light" @click="toggleEditDropdown"> Add</AppButton>
				
				<div v-if="isDropdownOpen" class="absolute right-0 mt-2 flex flex-col gap-2 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-[1000] w-[320px] ">
                    <div @click="addWebinar" class="flex flex-row gap-2 text-community-primary mb-3 items-center cursor-pointer"> <AppIcon  class="color-primary" src="plus"/> Add Webinar </div>
					<div v-for="(webinar, index) in webinars" :key="index" class="flex items-center w-full bg-red gap-2 mb-3 justify-between">
                        <div class="flex flex-row justify-between w-max">
                            <div class="space-x-2">
                                <input class="accent-community-primary " type="checkbox" :id="'webinar-checkbox-' + index" v-model="selectedWebinars" :value="webinar.data.product.id" />
                                <label :for="'webinar-checkbox-' + index">{{ webinar.data.product.title }}</label>
                            </div>
                            
                        </div>
						<div class="text-stan-gray-dark"> {{ webinar.data.product.webinar.sessions.length }}</div>
					</div>
					<div @click="applyFilter" class="cursor-pointer mt-1 font-bold">
						Done
					</div>
				</div>	
			</div>
		</div>

        <div v-for="(session, index) in flattenedSessions.slice(0,sessionCountToShow)" :key="index">
            <WebinarComponent :session="session" />
        </div>
		<div v-if="filteredSessionsLength > 3" class=" bg-stan-gray-primary w-full h-10 rounded-md flex flex-row justify-center items-center text-bold cursor-pointer" @click="toggleMoreWebinars">
			{{expanded ? "Show less" : "Show more" }}
		</div>
        <div @click="addWebinar"  v-if="filteredWebinars.length === 0" class="bg-white p-5 flex flex-col border-dotted border-2 border-stan-gray rounded-xl text-stan-text-light font-light text-sm items-center justify-center text-center hover:cursor-pointer select-none">
            Add Webinars to your community
        </div>
    </div>
</template>

<script setup>
	import { ref, computed, watch, onMounted, inject } from 'vue'
    import WebinarComponent from './WebinarComponent.vue';
    import { useCommunityStore } from '@/stores/communities';
    import AppButton from '../shared/AppButton.vue';
	import { configSettings } from '@/plugins/configSettings';
	import { useCommunityPublicStore } from '@/stores/public';
	import { useProductsStore } from '@/stores/products';
	import { DateTime } from 'luxon'
	import CONSTANTS from '@/global_helper/constants';

	const stanAnalytics = inject('stanAnalytics')
	const communityStore = useCommunityStore()
	const communityPublicStore = useCommunityPublicStore()
	const productsStore = useProductsStore()
	const isOwner = computed(() => communityStore.isOwner)
	const webinars = computed(() => {
		const now = new Date();
		return Array.from(productsStore.webinars).map(webinar => {
			const upcomingSessions = webinar.data.product.webinar.sessions.filter(session => {
				return session.datetime >= now;
			});
			if (upcomingSessions.length > 0) {
				return {
					...webinar,
					data: {
						...webinar.data,
						product: {
							...webinar.data.product,
							webinar: {
								...webinar.data.product.webinar,
								sessions: upcomingSessions
							}
						}
					}	
				};
			}
		}).filter(webinar => webinar);
	});
	const flattenedSessions = computed(() => {
		const upcomingWebinars = filteredWebinars.value;
		const sessions = upcomingWebinars.flatMap(webinar =>
			webinar.data.product.webinar.sessions.map(session => ({
				...session,
				timezone: webinar.data.product.webinar.timezone,
				image: webinar.data.product.image,
				title: webinar.data.product.title,
				duration_minutes: webinar.data.product.webinar.duration_minutes,
				webinar_slug: webinar.slug,
				webinar_id: webinar.data.product.id

			}))
		);
		const sortedSessions = sessions.sort((a, b) => {
			const sessionDateA = a.datetime
			const sessionDateB =b.datetime
			return sessionDateA - sessionDateB
		});
		filteredSessionsLength.value = sortedSessions.length
		return sortedSessions
	});


	const expanded = ref(false)
	const isDropdownOpen = ref(false)
	const sessionCountToShow = ref(3)
	const selectedWebinars = ref([])
	const filteredSessionsLength = ref(0)
	const selectedWebinarsInData = computed(() => communityStore.currentCommunity?.data?.selectedWebinars || [])
	if (selectedWebinarsInData.value.length > 0){
		selectedWebinars.value = selectedWebinarsInData.value
	}
	watch(selectedWebinarsInData, (newValue) => {
		if (newValue !== undefined) {
			selectedWebinars.value = newValue
		}
	})

	const toggleMoreWebinars = () => {
		if(sessionCountToShow.value === 3){
			sessionCountToShow.value = flattenedSessions.value.length
		}
		else{
			sessionCountToShow.value = 3
		}
		expanded.value = !expanded.value
	}

	const filteredWebinars = computed(() => {
		if (selectedWebinars.value.length === 0) {
			return []
		}
		return webinars?.value?.filter(webinar => selectedWebinars.value.includes(webinar.data.product.id))
	})

	const toggleEditDropdown = () => {
		if (isDropdownOpen.value){
			communityStore.updateCommunityData({
			selectedWebinars: selectedWebinars.value,
		})
		}
		isDropdownOpen.value = !isDropdownOpen.value
	}

	const applyFilter = async () => {
		communityStore.updateCommunityData({
			selectedWebinars: selectedWebinars.value
		})
		isDropdownOpen.value = false
	}

    const addWebinar = () => {
		stanAnalytics('community-new-webinar')
        window.open(`${configSettings.adminDomain}/stores/${communityPublicStore.store_id}/page/webinar/create?location=landing`,'blank')
    }
</script>

<style lang="scss" scoped>
	.helpful-link- {
		&wrapper {
		}
		&image {
			@apply rounded-full w-10 h-10;
		}
		&text-wrapper {
			@apply gap-0.5 flex flex-col;
		}
		&title {
			text-transform: capitalize;
		}
		&url {
			@apply text-stan-gray-dark;
		}
		&trash-button.app-button.link {
			@apply ml-auto mr-1 p-2;
			&:hover {
				background-color: var(--stan-gray-light-color) !important;
			}
		}
	}
	.posts-list-container {
		@apply w-full;
		.empty-post {
			@apply box-border border-dashed rounded-xl border-stan-gray-dark border;
			@apply w-full p-24 flex flex-col justify-center items-center;
			@apply text-stan-text-light text-base -tracking-32 leading-6;
		}
	}
	.buttons-wrapper {
		@apply flex flex-row gap-1;
	}
	.fade-move,
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		transform: scaleY(0.01) translate(30px, 0);
	}

	.fade-leave-active {
		position: absolute;
	}
</style>
