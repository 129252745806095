<template>
	<div class="app-audio-recorder-button-wrapper">
		<AppIcon id="audio-record-button" src="waveform" class="color-text-dark" @click="onRecordAudioClip" v-if="!isMessageEditMode" />
	</div>
	<AppRecorderHover class="audio-recording-dialog" :visible="showRecordingDialog" @close="onDialogHide">
		<div class="audio-recording-dialog--content">
			<AVMedia
				:media="currentRecord?.stream"
				:canv-width="100"
				:canv-height="30"
				type="frequ"
				:frequ-line-cap="true"
				:frequ-lnum="15"
				:line-width="3"
				line-color="#6355ff"
			/>
			<div class="recording-time">
				{{ recordingTimeStr }}
			</div>
		</div>
		<template v-slot:below-dialog>
			<AppButton size="xs" color="primary" class="action-button" @click="onSaveAudioRecord"> Ok </AppButton>
		</template>
	</AppRecorderHover>
</template>

<script setup>
	import { computed, defineEmits, defineProps, ref } from 'vue'
	import { EventEmitter } from 'events'
	import { AVMedia } from 'vue-audio-visual'
	import useUploadFile from '@/components/composables/useUploadFile'
	import { getRandomUUID } from '@/components/utils/uuid'
	import AppRecorderHover from '@/components/shared/AppRecorderHover.vue'
	import { notify } from '@kyvg/vue3-notification'

	const props = defineProps({
		scope: { type: String, default: 'default' },
		objectType: { type: String, default: 'audio' },
		messageEditMode: {
			type: Boolean,
			default: false,
		},
	})

	const emit = defineEmits([
		'recordingTime',
		'audioReady',
		'uploadProgress',
		'uploadDone',
		'uploadError',
		'audioFileReady',
		'audioFileProgress',
		'audioFileUploaded',
		'audioFileError',
		'audioRecorderOpened',
		'audioRecorderClosed',
	])

	function normalisation(n) {
		return n < 10 ? `0${n}` : n
	}

	function getAudioRecordName() {
		const d = new Date()
		return `${d.getFullYear().toString().substr(-2)}${normalisation(
			d.getMonth()
		)}${normalisation(d.getDate())}${normalisation(d.getHours())}${normalisation(d.getMinutes())}${normalisation(d.getSeconds())}`
	}

	class AudioRecord extends EventEmitter {
		constructor(options = {}) {
			super()
			this.scope = options.scope || 'default'
			this.objectType = options.objectType || 'audio'

			this.__id = getRandomUUID()
			this.name = getAudioRecordName()
			this.fullName = `${this.name}.wav`

			this.stream = null
			this.recorder = null
			this.recorderTimerInterval = null
			this.recorderTimeout = null
			this.recordingTime = 0
			this.saved = false

			this.emit = this.emit.bind(this)
		}

		get id() {
			return this.__id
		}

		get isSaved() {
			return this.saved
		}

		get recordingTimeStr() {
			const ss = this.recordingTime % 60
			const mm = (this.recordingTime - ss) / 60
			const s = ss < 10 ? `0${ss}` : ss
			return `${mm}:${s}`
		}

		async start() {
			try {
				if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
					this.stream = await navigator.mediaDevices.getUserMedia({ audio: true })
					this.recorder = new MediaRecorder(this.stream)
					const chunks = []
					this.recorder.addEventListener('dataavailable', e => {
						chunks.push(e.data)
					})
					this.recorder.addEventListener('stop', () => {
						this.stop()
						if (this.saved) {
							const blob = new Blob(chunks, { type: 'audio/wav' })
							this._saveAudioBlob(blob)
						}
					})
					this.recorder.addEventListener('start', this.onRecorderStart.bind(this))
					this.recorder.start()
				} else {
					throw new Error('Your browser does not support audio recording.')
				}
			} catch (error) {
				notify({ type: 'error', title: 'Uh Oh!', text: 'We were unable to obtain microphone permissions.' })
				console.error(error)
			}
		}

		onRecorderStart() {
			const self = this
			this.recorderTimerInterval = setInterval(() => {
				this.recordingTime += 1
				self.emit('recordingTime', { id: this.id, time: this.recordingTime })
			}, 1000)

			this.recorderTimeout = setTimeout(
				() => {
					this.saved = true
					this.recorder.stop()
				},
				5 * 60 * 1000
			)
		}

		save() {
			this.saved = true
			this.recorder.stop()
		}

		stop() {
			this.recorderTimerInterval != null && clearInterval(this.recorderTimerInterval)
			this.recorderTimerInterval = null
			this.recorderTimeout != null && clearTimeout(this.recorderTimeout)
			this.recorderTimeout = null
			this._recorderStop()
		}

		_saveAudioBlob(blob) {
			setTimeout(() => {
				this.emit('audioReady', {
					id: this.id,
					blob,
					time: this.recordingTimeStr,
					name: this.name,
				})
				const file = new File([blob], `${this.name}.wav`, { type: 'audio/wav' })
				file.metadata = { ...file.metadata, id: this.id }
				const uploader = useUploadFile({
					file,
					scope: this.scope,
					objectType: this.objectType,
					emit: this.emit,
				})
				uploader.addListener('assetUp', this._fileUploaded.bind(this))
				uploader.addListener('progress', this._progressUploaded.bind(this))
				uploader.addListener('errorUp', this._errorFileUploaded.bind(this))
			}, 200)
		}

		_progressUploaded(ev) {
			this.emit('uploadProgress', {
				id: this.id,
				progress: ev.progress,
			})
		}

		_fileUploaded(ev) {
			this.emit('uploadDone', {
				id: this.id,
				name: ev.name,
				src: ev.src,
				type: ev.type,
			})
		}

		_errorFileUploaded(ev) {
			this.emit('uploadError', {
				id: this.id,
				name: ev.error,
			})
		}

		_recorderStop() {
			this.recorder !== null && this.recorder.stop()
			this.stream !== null && this.stream.getTracks().forEach(track => track.stop())
			this.recorder = null
			this.stream = null
		}
	}
	// Todo.1 add for separate state data class AudioRecord { }
	// Todo.2 add delete from S3 method for audio files

	const showRecordingDialog = ref(false)
	const isMessageEditMode = computed(() => props.messageEditMode)
	const records = []
	const currentRecord = ref(null)

	const recordingTimeStr = computed(() => (currentRecord.value !== null ? currentRecord.value.recordingTimeStr : '--:--'))
	const onDialogShow = async () => {
		currentRecord.value = await newRecord({
			scope: props.scope,
			objectType: props.objectType,
		})
		currentRecord.value.start()

		currentRecord.value.addListener('audioReady', ev => {
			emit('audioFileReady', ev)
		})

		currentRecord.value.addListener('uploadProgress', ev => {
			emit('audioFileProgress', ev)
		})

		currentRecord.value.addListener('uploadDone', ev => {
			emit('audioFileUploaded', ev)
		})

		currentRecord.value.addListener('uploadError', ev => {
			emit('audioFileError', ev)
		})

		emit('audioRecorderOpened')
	}

	const onDialogHide = () => {
		showRecordingDialog.value = false
		currentRecord.value !== null && currentRecord.value.stop()
		if (currentRecord.value !== null && !currentRecord.value.isSaved) {
			const id = currentRecord.value.id
			const idx = records.findIndex(r => id === r.id)
			if (idx >= 0) {
				records.splice(idx, 1)
			}
		}
		currentRecord.value = null
		emit('audioRecorderClosed')
	}

	function onRecordAudioClip() {
		showRecordingDialog.value = true
		onDialogShow()
	}

	const onSaveAudioRecord = async () => {
		showRecordingDialog.value = false
		if (currentRecord.value !== null) {
			await currentRecord.value.save()
		}
	}

	const newRecord = async () => {
		const record = new AudioRecord()
		records.push(record)
		return record
	}

	function clearRecords() {
		currentRecord.value = null
		records.length = 0
	}

	defineExpose({ clearRecords, onRecordAudioClip })
</script>

<style lang="scss">
	.app-audio-recorder-button-wrapper {
		padding: 0;
		margin: 0;
		cursor: pointer;
		button#audio-record-button {
			padding: 0.1rem;
			margin: 0;
			border: none;

			svg {
				width: 17px;
				height: 17px;
				path,
				rect {
					fill: var(--stan-text-light-color);
				}
			}
		}
	}

	.audio-recording-dialog {
		.audio-recording-dialog--content {
			padding: 1rem;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 0.25rem;
		}

		.recording-time {
			display: block;
			width: 3.5ch;
		}
	}
</style>
