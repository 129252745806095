<template>
	<Teleport to="body">
	<AppModal
		class="about"
		ref="editAboutModal"
		title="About this Community"
		action="Save"
		:loading="loading"
		:backdrop="backdrop"
		@cta="editAboutUser"
		@close="close"
		:submitOnEnter="false"
	>
		<AppTextArea
			class="mb-0 text-area"
			v-model="content"
			label="Description"
			placeholder="Tell people about your community!"
			:error="v$.content.$errors"
		/>
	</AppModal>
</Teleport>
</template>

<script>
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'
	import { useCommunityStore } from '@/stores/communities'
	import commonHelpers from '@/global_helper/helpers'

	export default {
		setup: () => ({ v$: useVuelidate() }),
		props: {
			isEditing: {
				type: Boolean,
				default: false,
			},
			backdrop: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				content: null,
				email: null,
				selectedProducts: [],
				loading: false,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			showing() {
				return this.$refs.editAboutModal.showing
			},
		},
		validations() {
			return {
				content: {
					required: helpers.withMessage('Content is required', required),
				},
			}
		},
		methods: {
			async getAbout() {
				const about = (await this.communityStore.currentCommunity.data.about) || ''
				this.content = about
			},
			async editAboutUser() {
				const isFormCorrect = await this.v$.$validate()
				if (!isFormCorrect) {
					return
				}
				const payload = {
					about: this.content,
				}
				try {
					this.loading = true
					const user = await this.communityStore.updateCommunityData(payload)
					this.$notify({
						type: 'Success',
						title: `Done!`,
						text: `About section edited.`,
					})

					this.close()
				} catch (error) {
					if (error.response?.data?.message) {
						this.$notify({ type: 'error', text: error.response?.data?.message })
					}
				}
				this.loading = false
			},

			show() {
				this.getAbout()
				this.v$.$reset()
				this.$refs.editAboutModal.show()
			},
			close() {
				this.$emit('close')
				this.$refs.editAboutModal.hide()
			},
			productTagsUpdated(tags) {
				this.selectedProducts = tags.map(e => e.page_id)
			},
		},
		beforeMount() {
			this.getAbout()
		},
	}
</script>

<style lang="scss" scoped>
	.about.app-modal::v-deep {
		.modal {
			min-height: min(500px, 100%);
		}
	}

	.text-area {
		@apply flex flex-col grow;
		:deep(textarea) {
			@apply grow p-5 leading-5;
		}
	}
</style>
