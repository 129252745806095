<template>
	<!-- Mobile -->
	<div
		v-if="isMobile"
		class="sticky top-0 bg-white z-20 pb-5 w-full flex flex-row flex-nowrap px-4 md:px-10 items-center"
		:class="showTabs || showBack ? 'justify-between' : 'justify-end'"
	>
		<AppTabs
			v-if="showTabs"
			id="community-internal-tabs"
			:items="tabs"
			:active-tab="currentRouteName"
			@tabSelected="onTabSelected"
			class="w-[70%] text-nowrap overflow-x-scroll"
		/>
		<div v-else-if="showBack">
			<AppButton link @click="backClicked"> <AppIcon src="arrow-left" /> <span class="text-stan-text-dark">Back</span></AppButton>
		</div>

		<AppIcon src="menu" @click="toggleMobileMenu" class="cursor-pointer" :class="{ 'mt-5': !showTabs }" />
	</div>

	<!-- Desktop -->
	<div v-else class="sticky top-0 bg-white z-20 grid lg:grid-cols-3 grid-cols-2 gap-6 h-full px-4 md:px-10 overflow-x-hidden">
		<div class="justify-center col-span-2 flex flex-col">
			<AppTabs
				v-if="showTabs"
				id="community-internal-tabs"
				:items="tabs"
				:active-tab="currentRouteName"
				@tabSelected="onTabSelected"
				class="w-[70%] text-nowrap overflow-x-scroll"
			/>
			<div v-else-if="showBack" class="py-4">
				<AppButton link @click="backClicked"> <AppIcon src="arrow-left" /> <span class="text-stan-text-dark">Back</span></AppButton>
			</div>
		</div>
		<!-- On mobile show menu on desktop show search bar -->
		<div class="justify-center py-2" v-if="!isSearchPage">
			<div class="mt-4 md:mt-0 w-full rounded-full search-input px-4">
				<AppSearchInput class="w-full" @submit="search" />
			</div>
		</div>
	</div>
</template>

<script>
	import mobileCheckMixin from '@/layouts/MobileCheckMixin'
	export default {
		name: 'NavigationBar',
		mixins: [mobileCheckMixin],
		props: {
			tabs: {
				type: Array,
				require: true,
				default: () => [],
			},
		},
		computed: {
			currentRouteName() {
				return this.$route.name
			},
			showTabs() {
				if (['members', 'search'].includes(this.$route.name)) {
					return false
				}
				return true
			},
			showBack() {
				if (['members', 'search'].includes(this.$route.name)) {
					return true
				}
				return false
			},
			isSearchPage() {
				if (['search'].includes(this.$route.name)) {
					return true
				}
				return false
			},
		},
		methods: {
			toggleMobileMenu() {
				this.$emit('toggleMobileMenu')
			},
			onTabSelected(selected) {
				this.$router.push({ name: selected })
			},
			search(value) {
				if (value) {
					this.$router.push({ name: 'search', query: { q: value } })
				}
			},
			backClicked() {
				this.$router.back()
			},
		},
	}
</script>
<style lang="scss" scoped>
	.header-container {
		@apply h-16 md:h-60 w-screen relative;

		img {
			@apply h-16 md:h-60 w-full object-cover justify-center;
		}
	}
</style>
