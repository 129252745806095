<template>
	<div class="flex flex-col gap-3 items-start p-4">
		<div class="pb-6 pt-3 flex flex-row gap-2">
			<AppIcon src="box" />
			<h3 class="text-bold flex gap-1 items-center">Resources</h3>
		</div>
		<ResourceCard v-for="resource in limitedResources" :key="resource.resource_id" :resource="resource" :menuEnabled="false" />
		<AppButton size="xs" @click="viewAll" link class="mt-4">
			View All Resources
			<AppIcon src="arrow-right" class="resources-icon" />
		</AppButton>
	</div>
</template>

<script setup>
	import constants from '@/global_helper/constants'
	import { computed, inject } from 'vue'

	import { useCommunityStore } from '@/stores/communities'
	import { useRouter } from 'vue-router'
	const props = defineProps({
		resources: {
			type: Array,
			default: [],
		},
	})
	const stanAnalytics = inject('stanAnalytics')
	const emit = defineEmits(['go-to-resource'])

	const communityStore = computed(() => useCommunityStore())
	const canModifyChannels = computed(() => communityStore.value.canModifyChannels)

	const limitedResources = computed(() =>
		props.resources.filter(r => canModifyChannels.value || r.status !== constants.RESOURCE_STATUSES.INACTIVE).filter((_, idx) => idx < 2)
	)
	const router = useRouter()

	const viewAll = () => {
		stanAnalytics('community-sidebar-view-all-resources')
		router.push({ name: 'resources' })
	}
</script>

<style lang="scss" scoped>
	.resources-icon {
		:deep(path) {
			fill: var(--community-primary-color);
		}
	}
</style>
