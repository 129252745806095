<template>
	<div v-if="hasData || canModifyChannels" class="bg-white rounded-2xl p-4 relative">
		<div class="pb-6 pt-3 flex flex-row justify-between">
			<h3 class="text-bold flex gap-1 items-center">About</h3>
			<div v-if="canModifyChannels">
				<AppButton v-if="hasData" link icon="edit" size="md" color="light" @click="showEditor"> Edit</AppButton>
				<AppButton v-else link icon="plus" size="md" color="light" @click="showEditor"> Add</AppButton>
			</div>
		</div>

		<div
			v-if="hasData"
			class="bg-white p-5 flex flex-col font-light rounded-xl text-stan-text-light text-sm shadow-stan-box-shadow"
			:class="{ 'hover:cursor-pointer': canModifyChannels }"
			@click="showEditor"
		>
			<div class="whitespace-pre-wrap">
				{{ about }}
			</div>
		</div>
		<div
			v-else-if="canModifyChannels"
			class="bg-white p-5 flex flex-col border-dotted border-2 border-stan-gray rounded-xl text-stan-text-light text-sm items-center justify-center text-center hover:cursor-pointer"
			@click="showEditor"
		>
			Add an about section to your community!
		</div>
	</div>

	<EditAboutModal v-if="canModifyChannels" ref="editAboutModal" />
</template>
<script setup>
	import { ref, computed } from 'vue'
	import { useCommunityStore } from '@/stores/communities'

	const communityStore = useCommunityStore()
	const hasData = computed(() => !!communityStore.currentCommunity?.data?.about)
	const data = computed(() => communityStore.currentCommunity?.data)
	const about = computed(() => data.value?.about)
	const canModifyChannels = computed(() => communityStore.canModifyChannels)

	const editAboutModal = ref(null)
	const showEditor = () => {
		editAboutModal.value.show()
	}
</script>

<style lang="scss" scoped>
	.posts-list-container {
		@apply w-full;

		.empty-post {
			@apply box-border border-dashed rounded-xl border-stan-gray-dark border;
			@apply w-full p-24 flex flex-col justify-center items-center;
			@apply text-stan-text-light text-base -tracking-32 leading-6;
		}
	}
</style>
