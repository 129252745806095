import { DateTime } from 'luxon'

export default {
	isMobile(checkAgent = true) {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && checkAgent) || width < 1024) {
			return true
		}
		return false
	},
	UUID() {
		const lut = []
		for (let i = 0; i < 256; i++) {
			lut[i] = (i < 16 ? '0' : '') + i.toString(16)
		}
		const d0 = (Math.random() * 0xffffffff) | 0
		const d1 = (Math.random() * 0xffffffff) | 0
		const d2 = (Math.random() * 0xffffffff) | 0
		const d3 = (Math.random() * 0xffffffff) | 0
		return `${
			lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff]
		}-${lut[d1 & 0xff]}${lut[(d1 >> 8) & 0xff]}-${lut[((d1 >> 16) & 0x0f) | 0x40]}${
			lut[(d1 >> 24) & 0xff]
		}-${lut[(d2 & 0x3f) | 0x80]}${lut[(d2 >> 8) & 0xff]}-${lut[(d2 >> 16) & 0xff]}${
			lut[(d2 >> 24) & 0xff]
		}${lut[d3 & 0xff]}${lut[(d3 >> 8) & 0xff]}${lut[(d3 >> 16) & 0xff]}${lut[(d3 >> 24) & 0xff]}`
	},
	getTimeUTC() {
		const now = new Date()
		const year = now.getUTCFullYear()
		const month = now.getUTCMonth()
		const day = now.getUTCDate()
		const hours = now.getUTCHours()
		const minutes = now.getUTCMinutes()
		const seconds = now.getUTCSeconds()
		const milliseconds = now.getUTCMilliseconds()
		return new Date(year, month, day, hours, minutes, seconds, milliseconds)
	},
	validateEmail(email) {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	},
	lcs(X, Y) {
		const m = X.length
		const n = Y.length
		const L = Array(m + 1)
			.fill(null)
			.map(() => Array(n + 1).fill(0))

		for (let i = 0; i <= m; i++) {
			for (let j = 0; j <= n; j++) {
				if (i === 0 || j === 0) L[i][j] = 0
				else if (X[i - 1] === Y[j - 1]) L[i][j] = L[i - 1][j - 1] + 1
				else L[i][j] = Math.max(L[i - 1][j], L[i][j - 1])
			}
		}
		return L[m][n]
	},
	parseJwt(token) {
		try {
			const base64Url = token.split('.')[1]
			const base64 = base64Url.replace('-', '+').replace('_', '/')
			const payload = JSON.parse(atob(base64))

			return payload
		} catch (e) {
			console.error('Invalid token', e)
			return null
		}
	},
	getInitials(name) {
		name = name ?? ''

		const nameSplit = name.split(' ').filter(val => val)

		if (nameSplit.length > 1) {
			return nameSplit[0].substring(0, 1) + nameSplit[1].substring(0, 1)
		}
		return name.substring(0, 2)
	},
	playNotificationSound() {
		const notificationSound = new Audio('https://assets.stanwith.me/audio/chat-notification.mp3')
		notificationSound.volume = 0.3
		notificationSound.play()
	},
	desktopAppNotifier(id, title, body, silent, toRoute, router) {
		// tag & renotify automatically replaces the old notification with a new one and sends it
		const options = {
			body,
			tag: id,
			silent, // Most browesers don't allow sound for notification so we play our own
			renotify: true,
		}
		const notification = new Notification(title, options)
		notification.onclick = event => {
			event.preventDefault()
			router.push(toRoute)
			document.dispatchEvent(new Event('notificationClicked'))
		}
	},
	localSentTimeText(dateTimeIn) {
		const dateTime = new DateTime(dateTimeIn)
		if (Math.abs(dateTime.diffNow('hours').hours) > 1) {
			if (Math.abs(dateTime.diffNow('hours').hours) <= 24) {
				return dateTime.toRelative({ style: 'short', unit: 'hours' })
			}
			if (Math.abs(dateTime.diffNow('days').days) <= 6) return `${dateTime.toFormat('EEEE')}`
			if (Math.abs(dateTime.diffNow('days').days) <= 13) return `Last ${dateTime.toFormat('EEEE')}`
			if (Math.abs(dateTime.diffNow('days').days) <= 30) return dateTime.toRelative({ style: 'narrow' })
			return dateTime.toFormat('MMM dd, yyyy')
		}
		return `${this.localSentTime12Hour(dateTime)} ${this.localSentTimeMeridian(dateTime)}`
	},

	localSentTime12Hour(dateTime) {
		return dateTime.toFormat('hh:mm')
	},

	localSentTimeMeridian(dateTime) {
		return dateTime.toFormat('a')
	},

	digitalAssetType(file) {
		if (file?.type?.startsWith('image')) {
			return 'image'
		}

		if (file?.type?.startsWith('video')) {
			return 'video'
		}

		if (file?.type?.startsWith('audio')) {
			return 'audio'
		}

		return 'file'
	},
	digitalAssetTypeName(file) {
		if (file?.type?.split('/')[1]) {
			return file.type?.split('/')[1]
		}
		return 'File'
	},
	hasTargetedEventType(eventType, sseChannelTypePrefixes) {
		for (const prefix in sseChannelTypePrefixes) {
			if (eventType.startsWith(prefix)) {
				return true
			}
		}
		return false
	},
}
