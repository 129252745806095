<template>
	<div class="overflow-visible">
		<div class="stan-notification" :class="notifyClass">
			<div class="stan-icon" :class="notifyClass">
				<AppIcon class="md" :src="notifyIcon" />
			</div>
			<div class="notify-content">
				<span class="para-2 pr-1 text-bold notify-content-title">
					{{ data.title }}
				</span>
				<span class="para-2 notify-content-text" :class="{ 'text-bold': !data.title }">
					{{ data.text }}
				</span>
				<span v-if="notifyType === 'fact'" class="para-2 notify-content-link" @click="interactWithFact()"> View Analytics ➡️ </span>
			</div>
			<div>
				<button v-if="notifyType !== 'fact'" class="notify-close" :class="notifyClass" @click="$emit('close')">OK</button>
				<button v-else class="notify-close" :class="notifyClass" @click="closeFact()">
					<AppIcon :src="close" />
				</button>
			</div>
		</div>
	</div>
</template>
<script>
	import AppIcon from '../shared/AppIcon.vue'

	/*	Sample use:
		this.$notify({
			type: 'error', // can be 'info', 'warning', 'success', 'error'; can also be 'fact' for a different purpose
			title: 'Something went wrong',
			text: 'Malesuada fermentum tortoruada tum tortor',
			duration: 60000,
		})
	*/

	export default {
		name: 'StanNotification',
		props: {
			/**
			 * @typedef {Object} NotificationObject
			 * @property {'error'|'info'|'warning'|'success'} type
			 * @property {String} title
			 * @property {String} text
			 * @property {Number} duration
			 */
			/**
			 * @param {NotificationObject} data
			 */
			data: {
				type: Object,
				default() {
					return {}
				},
			},
		},
		data() {
			return {
				notificationsStyle: {
					info: { class: 'notify-info', icon: 'info' },
					warning: { class: 'notify-warning', icon: 'warning-circle' },
					success: { class: 'notify-success', icon: 'check-circle' },
					error: { class: 'notify-error', icon: 'warning-circle' },
					fact: { class: 'notify-fact', icon: 'fact' },
				},
			}
		},
		computed: {
			notifyType() {
				const nt = 'type' in this.data ? this.data.type.toLowerCase() : 'info'
				return nt in this.notificationsStyle ? nt : 'info'
			},
			notifyClass() {
				return this.notificationsStyle[this.notifyType].class
			},
			notifyIcon() {
				return this.notificationsStyle[this.notifyType].icon
			},
		},
		methods: {
			interactWithFact() {
				this.$emit('close')
			},
			closeFact() {
				this.$emit('close')
			},
		},
	}
</script>
<style lang="scss" scoped>
	.stan-notification {
		@apply rounded-full px-3 py-2.5 pr-5;
		box-shadow: 0 6px 8px rgb(71 25 117 / 9%);
		display: grid;
		gap: 10px;
		align-items: center;
		width: fit-content;
		height: 100%;
		margin: auto;
		margin-bottom: 1rem;
		background: var(--stan-primary-dark-color);
		grid-template-columns: 36px auto 36px;
		color: white;

		.notify-content {
			margin: auto;
			margin-right: 4px;
		}

		.notify-info {
			color: var(--stan-positive-soft-color);
		}
		.notify-warning {
			color: var(--stan-negative-primary-color);
		}
		.notify-success {
			color: var(--stan-positive-soft-color);
		}
		.notify-error {
			color: var(--stan-negative-primary-color);
		}
		&.notify-fact {
			background: var(--stan-white);
			box-shadow: 0px 20px 100px rgba(0, 16, 109, 0.1);
			color: var(--stan-text-dark-color);
			border: 2px solid var(--stan-gray-strike-color);
			padding: 25px;
			overflow: visible;
			grid-template-columns: 0px auto 36px;
			.notify-content {
				.notify-content-text {
					font-weight: 400 !important;
					font-size: 1rem;
					line-height: 1.375rem;
				}
				.notify-content-link {
					font-weight: 500 !important;
					font-size: 1rem;
					line-height: 1.375rem;
					cursor: pointer;
					color: var(--stan-primary-primary-color);
				}
			}
		}
		.stan-icon::v-deep {
			height: 30px;
			width: 30px;
			min-height: 30px;
			min-width: 30px;

			&.notify-info svg path {
				fill: var(--stan-positive-soft-color);
			}
			&.notify-warning svg path {
				fill: var(--stan-negative-primary-color);
			}
			&.notify-success svg path {
				fill: var(--stan-positive-soft-color);
			}
			&.notify-error svg path {
				fill: var(--stan-negative-primary-color);
			}
		}

		.notify-close {
			margin-left: 10px;
			&.notify-fact {
				background: var(--stan-gray-primary-color);
				aspect-ratio: 1;
				padding: 0;
				border-radius: 100%;
				svg {
					transform: scale(0.75);
				}
			}
		}
	}
</style>
