<template>
	<div :class="`stan-banner ${severity} ${noMargin ? '' : 'mb-8'}`">
		<div class="banner-content">
			<span class="banner-icon" v-if="icon">
				<AppIcon class="icon-svg" :src="icon" />
			</span>

			<div class="text-container">
				<span class="title h4 text-bold" v-if="title">
					{{ title }}
				</span>
				<span class="subtitle para-2" v-if="subtitle"> {{ subtitle }} </span>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppBanner',
		props: {
			icon: {
				type: String,
				default: '',
			},
			title: {
				type: String,
				default: '',
			},
			subtitle: {
				type: String,
				default: '',
			},
			severity: {
				type: String,
				default: 'info',
				validate: () => ['info'].includes(this.level),
			},
			noMargin: {
				type: Boolean,
				default: false,
			},
		},
	}
</script>

<style lang="scss" scoped>
	.stan-banner {
		@apply flex items-center justify-center rounded-xl p-5;

		&.info {
			@apply bg-stan-gray-soft;
		}

		.banner-content {
			@apply w-full flex flex-wrap items-center justify-between gap-4;

			.banner-icon {
				@apply transform -translate-y-px aspect-[1] hidden md:block;

				.icon-svg::v-deep {
					@apply h-6 w-6 fill-current;
					path {
						@apply fill-stan-text-dark;
					}
				}
			}
			.text-container {
				@apply flex flex-col items-start  flex-grow gap-2 w-full md:w-1/2;

				.title {
					@apply leading-[150%] align-middle tracking-normal;
				}
				.subtitle {
					@apply text-stan-text-light;
				}
			}
		}
	}
</style>
