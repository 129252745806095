<template>
	<div class="post-reactions-list-container">
		<transition-group name="slide">
			<PostReactionCell
				v-for="(reaction, index) in orderedReactions"
				:key="index"
				:isLike="isLike(reaction.name)"
				:name="reaction.name"
				:count="reaction.count"
				:selected="didISelect(reaction.member_ids)"
				:unicode="reaction.unicode"
				:reacted-members="reaction.member_ids"
			/>
		</transition-group>
		<PostReactButton v-if="!onlyLikes" />
		<div class="comment-reply-button" v-if="onlyLikes" @click="replyToComment">
			<span class="para-3 text-bold">Reply</span>
		</div>
	</div>
</template>
<script setup>
	import { defineProps, defineEmits, computed } from 'vue'
	import { useCommunityStore } from '@/stores/communities'

	const props = defineProps({
		reactions: {
			type: Array,
			default: [],
		},
		onlyLikes: {
			type: Boolean,
			default: false,
			required: false,
		},
		commentId: {
			type: Number,
			required: false,
		},
	})

	const emit = defineEmits(['reply-to-comment'])
	const communityStore = useCommunityStore()
	const myMemberId = communityStore.self.community_member_id
	const LIKE_NAME = '+1'

	const orderedReactions = computed(() => {
		let reactionsUse = props.reactions.filter(reaction => (props.onlyLikes ? reaction.name === LIKE_NAME : reaction.count > 0))
		// Always include likes
		if (!reactionsUse.find(reaction => reaction.name === LIKE_NAME)) {
			reactionsUse.push({ name: '+1', unicode: '👍️', count: 0, member_ids: [] })
		}

		// Always load likes first
		reactionsUse = [reactionsUse.find(reaction => reaction.name === LIKE_NAME), ...reactionsUse.filter(reaction => reaction.name !== LIKE_NAME)]
		return reactionsUse
	})

	const didISelect = memberIds => memberIds.includes(`${myMemberId}`)
	const isLike = name => name === LIKE_NAME
	const replyToComment = () => {
		emit('reply-to-comment')
	}
</script>

<style lang="scss" scoped>
	.post-reactions-list-container {
		@apply flex items-center justify-start gap-2.5 flex-wrap;
		.comment-reply-button {
			border-width: 1px;
			@apply border-dashed border-stan-gray-strike transition duration-200 ease-in-out hover:bg-stan-gray-light;
			@apply leading-none font-semibold -tracking-36;
			@apply py-1.5 px-2.5 rounded-full flex items-center justify-center gap-1.5 cursor-pointer;
		}
	}
</style>
