const CONSTANTS = {
	COMMUNITY_STATUS: {
		ACTIVE: 'active',
		INACTIVE: 'inactive',
	},
	COMMUNITY_ONBOARDING: {
		COMMUNITY_NAME: 'community_name',
		WALKTHROUGH_WELCOME: 'walkthrough_welcome',
		WALKTHROUGH_BANNER: 'walkthrough_banner',
		WALKTHROUGH_MESSAGE: 'walkthrough_message',
		WALKTHROUGH_ABOUT: 'walkthrough_about',
		WALKTHROUGH_LINKS: 'walkthrough_links',
		WALKTHROUGH_RESOURCES: 'walkthrough_resources',
	},
	MEMBERSHIP_STATUS: {
		ACTIVE: 1,
		INACTIVE: 0,
	},
	MEMBER_STATUS: {
		ACTIVE: 'active',
		INACTIVE: 'inactive',
		DELETED: 'deleted',
	},
	MESSAGE_STATUS: {
		ACTIVE: 'active',
		INACTIVE: 'inactive',
		DELETED: 'deleted',
		PINNED: 'pinned',
	},
	MESSAGE_NOTIFY: {
		DEFAULT: 'default',
		IMMEDIATE: 'immediate',
	},
	MEDIA_TYPES: {
		VIDEO: 'video',
		IMAGE: 'image',
		AUDIO: 'audio',
		FILE: 'file',
	},
	REACTION_TYPES: {
		LIKE: 'like',
		EMOJI: 'emoji',
	},
	NOTIFICATION_STATUS: {
		SENT: 'sent',
		DELIVERED: 'delivered',
		SEEN: 'seen',
		LATER: 'later',
		CLEARED: 'cleared',
	},
	FORUM_CHANNEL: 'forum',
	MEMBER_TYPES: {
		OWNER: 'owner',
		ADMIN: 'admin',
		MODERATOR: 'moderator',
		MEMBER: 'member',
	},
	PRODUCT_TYPE: {
		COURSE: 'course',
		DIGITAL_DOWNLOAD: 'digital-download',
		FULFILLMENT: 'fulfillment',
		LEAD_MAGNET: 'lead-magnet',
		LINK: 'link',
		MEETING: 'meeting',
		MEMBERSHIP: 'membership',
		WEBINAR: 'webinar',
		COMMUNITY: 'community',
	},
	TIPTAP_EMPTY_MESSAGE: '<p></p>',
	ROUTE_NAMES: {
		RESOURCE: 'resource',
		RESOURCES: 'resources',
		HOME: 'home',
		POST: 'post',
	},
	RESOURCE_STATUSES: {
		ACTIVE: 'active',
		INACTIVE: 'inactive',
	},
	DATE_TIME_FORMATS: {
		FULL_DATE_TIME: 'yyyy-MM-dd h:mm a',
		TIME_ONLY: 'h:mm a'

	}
}

export default CONSTANTS
