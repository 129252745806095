<template>
	<div class="profile-sub-section">
		<div class="profile-sub-section-left">
			<AppIcon class="color-dark" src="wallet" />
		</div>

		<div class="profile-sub-section-right" style="min-height: 130px">
			<div class="profile-sub-secion-right-heading">
				<div class="h3 text-bold h-full">Payments</div>
				<div v-if="membershipStatus == $constants.MEMBERSHIP_STATUS.ACTIVE" class="para-2 text-light h-full">Modify your subscription.</div>
				<div v-else class="para-2 text-light h-full">
					Your membership has been canceled and you will lose access to this community on {{ membershipEnd }}
				</div>
			</div>
			<Transition name="fade">
				<SolidDotsSpinner v-if="loadingMembership" color="var(--community-primary-color)" :dot-size="10" class="loader" />
				<div class="w-full" v-else>
					<Transition name="fade">
						<div
							v-if="membershipStatus == $constants.MEMBERSHIP_STATUS.ACTIVE && (!initIframe || loadingIFrame)"
							class="flex flex-col gap-5"
						>
							<div class="profile-sub-secion-right-name w-full" v-if="billingLink">
								<div class="text-sm font-semibold w-full -tracking-28 pb-1">Payment Method</div>
								<div class="flex flex-row gap-4">
									<div class="px-5 py-2.5 bg-stan-gray-light rounded-lg flex flex-row items-center justify-center gap-2.5">
										<div class="card-icon cart-type flex flex-row items-center justify-center">
											<span v-if="ccBrand == 'visa'"><Visa /></span>
											<span v-if="ccBrand == 'amex'"><Amex /></span>
											<span v-if="ccBrand == 'discover'"><Discover /></span>
											<span v-if="ccBrand == 'mastercard'"><Mastercard /></span>
										</div>
										**** **** **** {{ ccLast4 }}
									</div>
									<AppButton link color="primary" :loading="loadingIFrame" @click="updateCard">Change</AppButton>
								</div>
							</div>
							<div class="profile-sub-secion-right-name w-full">
								<AppButton @click="cancelSubscriptionConfirmation" outline color="light">Cancel Subscription</AppButton>
							</div>
						</div>
					</Transition>
					<Transition name="fade">
						<iframe
							v-if="initIframe"
							v-show="!loadingIFrame && showIframeDelayed"
							:key="iframeKey"
							:src="billingLink"
							ref="updatePaymentsEmbed"
							height="280"
							class="w-full lg:max-w-lg"
							@load="updateCardLinkIFrameLoaded"
						/>
					</Transition>
				</div>
			</Transition>
		</div>
	</div>

	<AppModal
		ref="cancelSubscriptionConfirmation"
		title="Just Confirming!"
		subheading="Are you sure you want to cancel your Community access?"
		action="Yes, Cancel My Access"
		secondaryAction="Never Mind"
		:loading="cancelSubscriptionConfirmationModalLoading"
		@cta="cancelSubscription"
		@secondary-cta="closeCancelSubscriptionConfirmation"
		@close="closeCancelSubscriptionConfirmation"
	>
	</AppModal>
</template>

<script>
	import { defineAsyncComponent } from 'vue'
	import { useCommunityStore } from '@/stores/communities'
	import { useCommunityPublicStore } from '@/stores/public'
	import { useMembershipStore } from '@/stores/membership'
	import AppIcon from '../shared/AppIcon.vue'

	export default {
		components: {
			Visa: defineAsyncComponent(() => import('@/assets/images/card_logos/Visa.svg')),
			Discover: defineAsyncComponent(() => import('@/assets/images/card_logos/Discover.svg')),
			Mastercard: defineAsyncComponent(() => import('@/assets/images/card_logos/Mastercard.svg')),
			Amex: defineAsyncComponent(() => import('@/assets/images/card_logos/Amex.svg')),
		},
		data() {
			return {
				loadingIFrame: false,
				showIframeDelayed: false, //Makes sure iframe fades in after details fade out
				initIframe: false,
				iframeKey: 1,
				loadingMembership: false,
			}
		},
		mounted() {
			window.addEventListener('message', this.receiveMessage, false)
		},
		unmounted() {
			window.removeEventListener('message', this.receiveMessage, false)
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
			membershipStore() {
				return useMembershipStore()
			},
			showPayments() {
				return this.membershipStore.status !== undefined
			},
			membershipStatus() {
				return this.membershipStore.status
			},
			membershipEnd() {
				return this.membershipStore.validUntil
			},
			ccLast4() {
				return this.membershipStore.ccLast4
			},
			ccBrand() {
				return this.membershipStore.ccBrand
			},
			billingLink() {
				if (this.membershipStore.billingUpdateLink) {
					// Store theme color
					const primaryHex = this.communityPublicStore.theme['community-primary-color']
					const primaryHexCode = primaryHex.substring(1)
					return `${this.membershipStore.billingUpdateLink}&embed=true&color=${primaryHexCode}`
				}
				return null
			},
		},
		methods: {
			updateCard() {
				this.loadingIFrame = true
				this.showIframeDelayed = false
				this.initIframe = true

				this.$nextTick(() => {
					this.iframeKey = this.iframeKey + 1
					console.log(this.$refs.updatePaymentsEmbed)
					window.a = this.$refs.updatePaymentsEmbed
				})
			},
			updateCardLinkIFrameLoaded() {
				this.loadingIFrame = false
				this.$nextTick(() => {
					setTimeout(() => {
						this.showIframeDelayed = true
					}, 250)
				})
			},
			async receiveMessage(event) {
				if (event && event?.data?.isStan) {
					if (event.data.type == 'SUCCESS') {
						this.initIframe = false
						this.$notify({ type: 'success', title: 'Success!', text: 'Your credit card details have been updated.' })
						this.loadingMembership = true
						await this.membershipStore.getMembership()
						this.loadingMembership = false
					} else if (event.data.type == 'ERROR') {
						this.$notify({
							type: 'error',
							title: 'Something went wrong',
							text: event.data.message,
						})
					}
				}
			},
			cancelSubscriptionConfirmation() {
				this.$refs.cancelSubscriptionConfirmation.show()
			},
			closeCancelSubscriptionConfirmation() {
				this.$refs.cancelSubscriptionConfirmation.hide()
			},
			async cancelSubscription() {
				this.cancelSubscriptionConfirmationModalLoading = true
				const status = await this.membershipStore.cancelMembership()
				if (!status) {
					this.$notify({ type: 'error', title: 'Uh Oh!', text: 'We were unable to cancel your membership, please try again.' })
				} else {
					this.$notify({
						type: 'success',
						title: 'Success!',
						text: 'Your membership has been cancelled successfully. You will lose access on ' + this.membershipEnd,
					})
				}
				this.closeCancelSubscriptionConfirmation()
				this.cancelSubscriptionConfirmationModalLoading = false
			},
		},
	}
</script>

<style lang="scss" scoped>
	.profile-section {
		@apply flex w-full md:p-5 flex-col items-start gap-10 sm:gap-5;
		.profile-sub-section {
			display: flex;
			align-items: flex-start;
			gap: 12px;
			align-self: stretch;
			&-left {
				display: flex;
				width: 22px;
			}
			&-right {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 20px;
				flex: 1 0 0;
			}
			.profile-sub-secion-right-heading {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;
			}
			.profile-sub-secion-right-image {
				max-width: 100px;
				.profile-photo-wrapper {
					position: relative;
					.abs-add-image {
						position: absolute;
						right: -7px;
						bottom: -8px;
						display: inline-block;
						img {
							width: 20px;
						}
					}
				}
			}
		}
	}

	.close-container {
		@apply flex w-full justify-end absolute right-3 top-3;

		.close {
			@apply flex w-6 h-6 bg-stan-gray-light rounded-full justify-center items-center cursor-pointer p-1;
		}
		&.editor-close {
			@apply flex w-full justify-end absolute right-0 top-0;
		}
	}

	.opacity-0 {
		opacity: 0.3;
	}
</style>
