<template>
	<div id="video-wrapper" class="video-wrapper">
		<div id="artplayer-app" class="artplayer-app art-auto-size">
			<div class="art-video-player art-subtitle-show art-layer-show art-control-show art-mask-show">
				<video :id="videoId" preload="none" class="art-video" controls controlslist="nodownload">
					Your browser does not support the video element.
				</video>
				<div id="video-poster-src" v-show="!isPlayStarted" class="art-poster" :style="`background-image:url(${imagePreview})`"></div>
				<div id="video-mask-src" v-show="isVideoReady && !isPlayStarted" class="art-mask rounded-xl" @click="togglePlay">
					<div class="art-state">
						<img v-if="playIcon !== ''" :src="playIcon" class="ml-2" alt="playIcon" />
						<i v-else class="art-icon art-icon-state">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								height="60"
								width="60"
								style="filter: drop-shadow(0px 1px 1px black)"
								viewBox="0 0 24 24"
							>
								<path
									d="M20,2H4C1.8,2,0,3.8,0,6v12c0,2.2,1.8,4,4,4h16c2.2,0,4-1.8,4-4V6C24,3.8,22.2,2,20,2z M15.6,12.8L10.5,16 C9.9,16.5,9,16,9,15.2V8.8C9,8,9.9,7.5,10.5,8l5.1,3.2C16.3,11.5,16.3,12.5,15.6,12.8z"
								></path>
							</svg>
						</i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Hls from 'hls.js'
	import {
		isImageProcessing,
		preloadImage,
		getOptimizeImageUrl,
		isSourceAvailable,
		isSourceBase64Img,
		isSourceSvgImg,
		checkImage,
	} from './OptimizationImageTools'
	import { emptyImage } from './ImagePreview'

	export default {
		name: 'VideoCmp',
		props: {
			videoId: {
				type: String,
				default() {
					return 'video-src'
				},
			},
			videoFormat: {
				type: String,
				default() {
					return 'desktop'
				},
			},
			videoWidth: {
				type: Number,
				default() {
					return 600
				},
			},
			videoHeight: {
				type: Number,
				default() {
					return 337
				},
			},
			videoSrc: {
				type: String,
				default() {
					return ''
				},
			},
			videoStreamSrc: {
				type: String,
				default() {
					return ''
				},
			},
			videoPreviewAsync: {
				type: Boolean,
				default() {
					return true
				},
			},
			videoPreviewQuality: {
				type: Boolean,
				default() {
					return true
				},
			},
			playIcon: {
				type: String,
				default() {
					return 'https://assets.stanwith.me/graphics/video-play.svg'
				},
			},
			primaryColor: {
				type: String,
				default: '#5383ff',
			},
			fcpComplete: {
				type: Boolean,
				default: false,
			},
			avoidDefaultSpacing: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				isVideoLoaded: false,
				isPlayStarted: false,
				backgroundImage: emptyImage,
				isVideoPreviewImageReady: false,
				checkVideoPreviewInterval: null,
				checkingVideoPreview: false,
				preloadImageStart: false,
			}
		},
		computed: {
			imagePreview() {
				return this.isVideoPreviewImageReady ? this.backgroundImage : emptyImage
			},
			isVideoReady() {
				return this.fcpComplete
			},
		},
		async mounted() {
			// this.preloadBackgroundImage()
			if (this.fcpComplete) {
				this.initVideo()
			}
		},
		methods: {
			async checkVideoPreviewImage(src) {
				if (!this.checkingVideoPreview) {
					this.checkingVideoPreview = true
					this.isVideoPreviewImageReady = await checkImage(src)
					this.checkingVideoPreview = false
				}
				if (this.isVideoPreviewImageReady) {
					clearInterval(this.checkVideoPreviewInterval)
					this.checkVideoPreviewInterval = null
				}
			},
			async preloadBackgroundImage() {
				this.preloadImageStart = true
				const image = `${this.videoSrc}/preview.jpg`
				this.isVideoPreviewImageReady = await checkImage(image)
				if (this.isVideoPreviewImageReady) {
					if (!isImageProcessing(this.videoSrc)) {
						this.backgroundImage = image
						return
					}
					const optimizeImageOptions = {
						format: 'webp',
						quality: 80,
						width: 433,
					}
					const optimizeImageSrc = await getOptimizeImageUrl(image, {
						...optimizeImageOptions,
					})
					preloadImage(optimizeImageSrc)
						.then(() => {
							this.backgroundImage = optimizeImageSrc
						})
						.catch(err => {
							this.backgroundImage = image
						})
				} else if (this.checkVideoPreviewInterval === null) {
					this.checkVideoPreviewInterval = setInterval(() => {
						this.checkVideoPreviewImage(image)
					}, 3000)
				}
				this.preloadImageStart = false
			},
			initVideo() {
				const videoWrapper = document.getElementById('video-wrapper')
				const wrapperWidth = videoWrapper.offsetWidth
				const wrapperHeight = videoWrapper.offsetHeight
				const fullWidth = wrapperWidth
				let fullHeight = wrapperHeight

				this.$nextTick(() => {
					const videoPlayer = document.getElementById(this.videoId)
					if (this.videoStreamSrc && Hls.isSupported()) {
						const hls = new Hls()
						hls.loadSource(this.videoStreamSrc)
						hls.attachMedia(videoPlayer)
						hls.on(Hls.Events.ERROR, function (event, data) {
							if (data.fatal) {
								// if HLS encounters a fatal error, use the original copy instead
								videoPlayer.src = this.videoSrc
							}
						})
					} else if (this.videoStreamSrc && videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
						videoPlayer.src = this.videoStreamSrc
					} else {
						videoPlayer.src = this.videoSrc
						setTimeout(() => {
							videoPlayer.load()
							videoPlayer.setAttribute('preload', 'auto')
						}, 1000)
					}

					videoPlayer.addEventListener('loadeddata', () => {
						this.isVideoLoaded = true
						if (videoPlayer.videoWidth > 0 && videoPlayer.videoHeight > 0) {
							const ratio = videoPlayer.videoWidth / fullWidth
							fullHeight = parseInt(videoPlayer.videoHeight / ratio)
						}
					})

					videoPlayer.addEventListener('ready', () => {
						if (videoPlayer.videoWidth > 0 && videoPlayer.videoHeight > 0) {
							const ratio = videoPlayer.videoWidth / fullWidth
							fullHeight = parseInt(videoPlayer.videoHeight / ratio)
						}
					})

					videoPlayer.addEventListener('play', () => {
						const playVideoWrapper = document.getElementById('video-wrapper')
						const artplayerApp = document.getElementById('artplayer-app')

						if (this.avoidDefaultSpacing) {
							const playFullWidth = playVideoWrapper.offsetWidth
							const playFullHeight = playVideoWrapper.offsetHeight

							videoPlayer.style.width = `${playFullWidth}px`
							videoPlayer.style.height = `${playFullHeight}px`
							videoWrapper.style.height = `${playFullHeight}px`
							artplayerApp.style.width = `${playFullWidth}px`
							artplayerApp.style.height = `${playFullHeight}px`
						} else {
							const playFullWidth = playVideoWrapper.offsetWidth - 46
							const playFullHeight = playVideoWrapper.offsetHeight - 48

							videoPlayer.style.width = `${playFullWidth}px`
							videoPlayer.style.height = `${playFullHeight}px`
							videoWrapper.style.height = `${playFullHeight + 48}px`
							artplayerApp.style.width = `${playFullWidth}px`
							artplayerApp.style.height = `${playFullHeight + 48}px`
						}

						this.isPlayStarted = true
					})

					videoPlayer.addEventListener('pause', () => {})

					videoPlayer.addEventListener('timeupdate', () => {
						this.$emit('timeupdate', videoPlayer.duration, videoPlayer.currentTime)
					})
					videoPlayer.addEventListener('contextmenu', e => e.preventDefault())
				})
			},
			togglePlay(e) {
				e.preventDefault()
				const videoPlayer = document.getElementById(this.videoId)
				if (this.isPlayStarted) {
					videoPlayer.pause()
					this.isPlayStarted = false
				} else {
					const playPromise = videoPlayer.play()
					if (playPromise !== undefined) {
						playPromise
							.then(() => {
								const playVideoWrapper = document.getElementById('video-wrapper')
								const artplayerApp = document.getElementById('artplayer-app')

								if (this.avoidDefaultSpacing) {
									const playFullWidth = playVideoWrapper.offsetWidth
									const playFullHeight = playVideoWrapper.offsetHeight

									videoPlayer.style.width = `${playFullWidth}px`
									videoPlayer.style.height = `${playFullHeight}px`
									playVideoWrapper.style.height = `${playFullHeight}px`
									artplayerApp.style.width = `${playFullWidth}px`
									artplayerApp.style.height = `${playFullHeight}px`
								} else {
									const playFullWidth = playVideoWrapper.offsetWidth - 46
									const playFullHeight = playVideoWrapper.offsetHeight - 48

									videoPlayer.style.width = `${playFullWidth}px`
									videoPlayer.style.height = `${playFullHeight}px`
									playVideoWrapper.style.height = `${playFullHeight + 48}px`
									artplayerApp.style.width = `${playFullWidth}px`
									artplayerApp.style.height = `${playFullHeight + 48}px`
								}

								this.isPlayStarted = true
							})
							.catch(err => {
								console.error(err)
							})
					}
				}
			},
		},
		watch: {
			fcpComplete(isReady) {
				if (isReady) {
					this.initVideo()
				}
			},
			videoSrc() {
				this.backgroundImage = emptyImage
				this.preloadBackgroundImage()
			},
			isVideoPreviewImageReady(val) {
				if (val) {
					clearInterval(this.checkVideoPreviewInterval)
					this.checkVideoPreviewInterval = null
				}
				if (!this.preloadImageStart) {
					this.preloadBackgroundImage()
				}
			},
		},
	}
</script>

<style lang="scss">
	.video-wrapper {
		width: 375px;
		video {
			position: relative;
			width: 100%;
			justify-content: center;
			align-items: center;
			padding: 0 0;
			border-radius: 0.75rem;
		}
	}
	.no-radius {
		video {
			border-radius: 0px !important;
		}
		.art-poster {
			border-radius: 0px !important;
		}
	}

	.artplayer-app {
		background-color: transparent !important;
		border: none;
		width: 414px;
		height: 248px;
		&.art-auto-size {
			align-items: center;
			display: flex;
			justify-content: center;
			width: 100%;
			aspect-ratio: 1.65;
			height: auto;
		}
		.art-video-player {
			width: 100%;
			height: 99.9636%;
			background-color: transparent !important;
			zoom: 1;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			-ms-high-contrast-adjust: none;
			color: #eee;
			direction: ltr;
			display: flex;
			font-family: Roboto, Arial, Helvetica, sans-serif;
			font-size: 14px;
			line-height: 1.3;
			margin: 0 auto;
			outline: 0;
			position: relative;
			text-align: left;
			touch-action: manipulation;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			z-index: 1;
		}
		.art-video-player {
			* {
				box-sizing: border-box;
				margin: 0;
				padding: 0;
			}
			.art-video {
				bottom: 0;
				height: 100%;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				cursor: pointer;
				z-index: 0;
			}
			.art-poster {
				bottom: 0;
				height: 100%;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				background-position: 50%;
				background-repeat: no-repeat;
				background-size: cover;
				pointer-events: none;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				z-index: 1;
				border-radius: 20px;
			}
			.art-mask {
				display: flex;
				align-items: center;
				bottom: 0;
				height: 100%;
				justify-content: center;
				left: 0;
				overflow: hidden;
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				z-index: 3;
				background: rgba(0, 0, 0, 0.25);
				.art-state {
					display: flex;
					width: 98px;
					height: 98px;
					border-radius: 100%;
					align-items: center;
					justify-content: center;
					position: absolute;
					opacity: 1 !important;
					right: auto !important;
					bottom: auto !important;
					animation: slideup 1.2s ease-in-out;
					z-index: 5;
				}
			}
			.art-icon {
				align-items: center;
				display: inline-flex;
				justify-content: center;
				line-height: 1.5;
				svg {
					fill: #fff;
				}
			}
		}
	}
	@media (max-width: 420px) {
		.art-auto-size {
			.art-state {
				width: 89px !important;
				height: 89px !important;
				background-size: 22px;
				background-position: calc(50% + 3px) calc(50% - 0px);
			}
		}
	}
	@media (max-width: 380px) {
		.art-auto-size {
			.art-state {
				width: 79px !important;
				height: 79px !important;
				background-size: 20px;
				background-position: calc(50% + 3px) calc(50% - 0px);
			}
		}
	}
	@media (max-width: 360px) {
		.art-auto-size {
			.art-state {
				width: 79px !important;
				height: 79px !important;
				background-size: 18px;
				background-position: calc(50% + 3px) calc(50% - 0px);
				img {
					width: 30px !important;
					height: 30px !important;
				}
			}
		}
	}
	@media (max-width: 320px) {
		.art-auto-size {
			.art-state {
				width: 65px !important;
				height: 65px !important;
				background-size: 18px;
				background-position: calc(50% + 3px) calc(50% - 0px);
				img {
					width: 30px !important;
					height: 30px !important;
				}
			}
		}
	}
	@media (max-width: 280px) {
		.art-auto-size {
			.art-state {
				width: 55px !important;
				height: 55px !important;
				background-size: 14px;
				background-position: calc(50% + 3px) calc(50% - 0px);
				img {
					width: 25px !important;
					height: 25px !important;
				}
			}
		}
	}
</style>
