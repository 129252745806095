<template>
	<AppCard class="overflow-visible cursor-pointer" @click="viewResource">
		<div class="resource-card-container relative">
			<img :src="resourceData.banner_image" class="rounded-t-md" />
			<div class="details">
				<div class="content">
					<div class="title para-1 text-bold !flex !flex-nowrap items-center justify-between">
						<span class="line-clamp-3">{{ resourceData.title }}</span>
						<div class="flex items-center">
							<div
								v-if="resourceStatus === constants.RESOURCE_STATUSES.INACTIVE"
								class="h-4 p-1.5 rounded bg-community-dark flex items-center justify-center text-white para-3"
							>
								Draft
							</div>
							<AppSelectDropdown
								v-if="canModifyChannels && menuEnabled"
								:menu="true"
								dropdown-menu-class="!min-w-max"
								:options="selectOptions"
								@value-selected="value => valueSelected(value)"
							/>
						</div>
					</div>
					<div class="description text-light para-2 line-clamp-5">{{ resourceData.subHeading }}</div>
				</div>
			</div>
		</div>
		<Teleport to="body">
			<AppModal
				ref="deleteModal"
				title="Just Confirming!"
				subheading="Are you sure you want to delete this resource?"
				action="Delete Resource"
				secondaryAction="Never Mind"
				:loading="modalLoading"
				@cta="deleteResource"
				@secondary-cta="closeDeleteModal"
				@close="closeDeleteModal"
			>
			</AppModal>
		</Teleport>
	</AppCard>
</template>

<script setup>
	import { defineProps, ref, computed, inject } from 'vue'
	import { useRouter } from 'vue-router'
	import { useResourceStore } from '@/stores/resources'
	import constants from '@/global_helper/constants'
	import { useCommunityStore } from '@/stores/communities'

	const props = defineProps({
		resource: {
			type: Object,
			required: false,
		},
		menuEnabled: {
			type: Boolean,
			required: false,
			default: true,
		},
	})

	const stanAnalytics = inject('stanAnalytics')

	const router = useRouter()

	const resourceStore = computed(() => useResourceStore())
	const communityStore = computed(() => useCommunityStore())
	const canModifyChannels = computed(() => communityStore.value.canModifyChannels)
	const resourceData = computed(() => props.resource?.data)
	const resourceStatus = computed(() => props.resource?.status)

	const deleteModal = ref(false)
	const modalLoading = ref(false)

	const selectOptions = computed(() => {
		return [
			{value: 'delete', label: 'Delete', iconBefore: 'trash'}
		]
	})

	const valueSelected = (value) => {
		switch (value) {
			case 'delete':
				showDeleteModal()
				break
		}
	}
	
	// Delete modal
	const showDeleteModal = () => {
		if (props.resource?.resource_id) {
			deleteModal.value.show()
		}
	}

	const closeDeleteModal = () => {
		deleteModal.value.hide()
	}

	const deleteResource = async () => {
		modalLoading.value = true
		stanAnalytics('community-resource-delete',{
			props: {resource_id: props.resource?.resource_id}
		})
		await resourceStore.value.deleteResource(props.resource?.resource_id)
		modalLoading.value = false
	}

	const viewResource = () => {
		if (props.resource?.resource_id) {
			stanAnalytics('community-resource-view', {
				props: { resource_id: props.resource?.resource_id, menuEnabled: props?.menuEnabled },
			})
			router.push({
				name: 'resource',
				params: {
					resourceId: props.resource.resource_id,
				},
			})
		}
	}
</script>
<style lang="scss" scoped>
	.card {
		width: 340px;
	}

	img {
		@apply aspect-video object-cover;
	}

	.resource-card-container {
		@apply flex flex-col w-full overflow-y-visible cursor-pointer;
		height: 100%;
	}

	.details {
		@apply p-4 grow flex flex-col gap-5;

		.content {
			@apply gap-2;
			flex: 0 1 auto;
			@apply flex flex-col;

			.title {
				@apply line-clamp-3 overflow-visible;
			}

			.description {
				@apply -tracking-24 text-stan-text-dark line-clamp-5 overflow-hidden;
				flex: 0 1 auto;
			}
		}
	}
</style>
