<template>
	<div class="single-comment-section-container">
		<div class="header">
			<span class="para-2 text-light text-bold">{{ totalCommentsText }}</span>
			<AppButton class="view-all-link" link @click="viewAll">{{ latestComment && showComment ? 'View All' : 'View Post' }}</AppButton>
		</div>
		<Comment
			v-if="latestComment && showComment"
			:key="totalComments"
			:message-id="latestComment.message_id"
			:parentMessageId="messageId"
			:show-reactions="false"
			:show-actions="false"
			:shouldShowSubComments="false"
			:channelName="channelName"
		/>
		<CommentReply v-if="quickReply" :messageId="messageId" :channelName="channelName" />
	</div>
</template>
<script setup>
	import { defineProps, defineEmits, watch, computed } from 'vue'
	import { useCommunityStore } from '@/stores/communities'

	const props = defineProps({
		messageId: {
			type: Number,
			description: 'Id of the message to respond too',
		},
		showComment: {
			type: Boolean,
			required: false,
			default: true,
		},
		quickReply: {
			type: Boolean,
			required: false,
			default: true,
		},
	})
	const communityStore = useCommunityStore()


	const channel_id = computed(() => communityStore.messages[props.messageId].channel_id)
	const channelName = computed(() => communityStore.channelIdToNameMap[channel_id.value])
	const comments = computed(() => communityStore.getChannelThread(channelName.value)[props.messageId]?.messages || {})
	const totalComments = computed(() => calculateAmountOfComments())
	const latestComment = computed(() => {
		if (totalComments.value > 0) {
			const keys = Object.keys(comments.value)
			return comments.value[keys[keys.length - 1]]
		}
		return null
	})

	const totalCommentsText = computed(() => {
		if (totalComments.value === 1) return '1 Comment'
		return `${totalComments.value} Comments`
	})

	const emit = defineEmits(['viewAll'])

	const viewAll = () => emit('viewAll')
	const calculateAmountOfComments = () => {
		const commentsArray = Object.values(comments.value ?? {})
		return commentsArray.length + commentsArray.reduce((acc, curr) => acc + (curr.thread_count || 0), 0)
	}
</script>

<style lang="scss" scoped>
	.single-comment-section-container {
		@apply p-5 flex flex-col gap-5 bg-stan-gray-light rounded-b-lg;

		.header {
			@apply flex flex-row items-center justify-between;
			.view-all-link {
				@apply rounded-none;
			}
		}
	}
</style>
